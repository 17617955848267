<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">主页</el-breadcrumb-item>
      <el-breadcrumb-item>产品营销</el-breadcrumb-item>
      <el-breadcrumb-item>营销管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row :gutter="30">
        <el-form
          :model="queryinfo"
          ref="queryinfoRef"
          :inline="true"
          style="text-align: left; margin-left: 40px"
        >
          <el-form-item prop="name">
            <div style="display: inline-block" class="divSpan">
              销售/运营名称：
            </div>
            <div style="display: inline-block">
              <el-input
                v-model.trim="queryinfo.name"
                placeholder="请输入"
                clearable
              ></el-input>
            </div>
          </el-form-item>

          <el-button type="primary" icon="el-icon-search" @click="seachChange"
            >查询</el-button
          >
          <el-button type="info" icon="el-icon-refresh" @click="clean"
            >重置</el-button
          >
        </el-form>
      </el-row>

      <el-row :gutter="30" style="display: flex">
        <el-table
          :data="saleList"
          :header-cell-style="{
            textAlign: 'center',
            background: '#FAFAFA',
            color: 'rgba(0, 0, 0, 0.85)',
            fontWeight: '500',
          }"
          tooltip-effect="dark"
          :cell-style="{
            textAlign: 'center',
          }"
        >
          <el-table-column type="index" min-width="50px"></el-table-column>
          <el-table-column
            prop="name"
            label="销售/运营名称"
            min-width="100px"
          ></el-table-column>
          <el-table-column
            prop="phone"
            label="手机号"
            min-width="100px"
          ></el-table-column>

          <el-table-column
            prop="joinDate"
            label="入职日期"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="sales"
            label="累计销售额(元)"
            min-width="150px"
          ></el-table-column>

          <el-table-column label="操作" min-width="200px" fixed="right">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="details(scope.row)"
                >查看专属链接</el-button
              >
              <el-button
                size="mini"
                type="primary"
                @click="Namedetail(scope.row)"
                >查看销售数据</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-row>

      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryinfo.pageNum"
        :page-size="queryinfo.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <el-dialog
      :close-on-click-modal="false"
      title="专属优惠活动链接"
      :visible.sync="linkDialog"
      width="50%"
    >
      <el-row style="display: flex">
        <el-table
          :data="salelinkList"
          :header-cell-style="{
            textAlign: 'center',
            background: '#FAFAFA',
            color: 'rgba(0, 0, 0, 0.85)',
            fontWeight: '500',
          }"
          tooltip-effect="dark"
          :cell-style="{
            textAlign: 'center',
          }"
        >
          <el-table-column
            prop="activityName"
            label="活动名称"
            min-width="100px"
          ></el-table-column>
          <el-table-column label="活动时间" min-width="250px">
            <template slot-scope="scope"
              >{{ scope.row.startTime }}--{{ scope.row.endTime }}</template
            >
          </el-table-column>

          <el-table-column label="专属链接" min-width="200px">
            <template slot-scope="scope">
              {{ scope.row.url }}
              <el-button
                type="text"
                @click="copyText(scope.row.url)"
                icon="el-icon-copy-document"
              ></el-button>
            </template>
          </el-table-column>
          <el-table-column label="二维码" min-width="150px">
            <template slot-scope="scope">
              <img :src="scope.row.qrcode" alt="" style="height: 100px" />
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="exportActionLink"
          >导出活动链接</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>

import { getSellerList, getLinkList } from '@/api/ActionSale.js'
import { exportRecord } from '@/components/myself/DownloadFile'

export default {

  data () {
    return {
      queryinfo: {
        name: '',
        pageNum: 1,
        pageSize: 20,
      },
      total: 0,
      saleList: [],

      linkDialog: false,
      salelinkList: [],
      sellerId: ''
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    async getList () {
      const res = await getSellerList(this.queryinfo.pageNum, this.queryinfo.pageSize, this.queryinfo.name)
      if (res.status !== 200) return this.$message.error('获取失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.saleList = res.data.data.list
      this.total = res.data.data.total
    },
    clean () {
      this.$refs.queryinfoRef.resetFields()
      this.getList()
    },
    dateSerach () {
      this.getList()
    },
    seachChange () {
      this.queryinfo.pageNum = 1
      this.getList()
    },
    handleSizeChange (newSize) {
      this.queryinfo.pageSize = newSize
      this.getList()
    },
    handleCurrentChange (newPage) {
      this.queryinfo.pageNum = newPage
      this.getList()
    },

    async details (para) {
      this.sellerId = para.id
      const res = await getLinkList(para.id)
      if (res.status !== 200) return this.$message.error('获取失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.salelinkList = res.data.data
      this.linkDialog = true
    },
    copyText (para) {
      // var input = document.getElementById("copyInput")
      // input.value = para
      // input.select()
      // document.execCommand("copy")
      var input = document.createElement('input')
      input.value = para
      document.body.appendChild(input)
      input.select()
      document.execCommand("copy")
      this.$message.success('复制成功')
      input.remove()
    },

    Namedetail (para) {
      this.$router.push({ path: '/saleData', query: { id: para.id, name: para.name } })
    },

    async exportActionLink () {
      exportRecord({ sellerId: this.sellerId }, '活动链接', 4)

    }
  }
}
</script>
<style lang="less" scoped>
.divSpan {
  font-size: 16px;
}

/deep/ .el-form-item__content {
  margin-right: 30px;
}

.buttons {
  float: right;
  margin-right: 100px;
}
</style>